<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <!-- <div>
        <el-input placeholder="请输入路线" v-model="line"></el-input>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div> -->

      <el-button round type="primary" style="width: 150px" @click="handleAdd"
        >新建</el-button
      >
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="路线名称" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.startCityName
            }}<i
              class="iconfont icon-double-arrow"
              style="font-size: 14px; margin: 0 5px"
            ></i
            >{{ scope.row.endCityName }}
          </template>
        </el-table-column>
        <el-table-column prop="nftArtist" label="NFT艺术家" width="180">
        </el-table-column>
        <el-table-column
          prop="nftContract"
          label="合约地址"
          width="220"
        ></el-table-column>
        <el-table-column prop="transChannel" label="购买渠道" width="180">
        </el-table-column>
        <el-table-column prop="uid1.phone" label="管理员1" width="130">
        </el-table-column>
        <el-table-column prop="uid2.phone" label="管理员2" width="130">
        </el-table-column>
        <el-table-column prop="uid3.phone" label="管理员3" width="130">
        </el-table-column>
        <el-table-column prop="nftUrl1" label="NFT系列图1" width="170">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.nftUrl1"
              :preview-src-list="[scope.row.nftUrl1]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nftUrl1" label="NFT系列图2" width="170">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.nftUrl2"
              :preview-src-list="[scope.row.nftUrl2]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nftUrl1" label="NFT系列图3" width="170">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.nftUrl3"
              :preview-src-list="[scope.row.nftUrl3]"
            >
            </el-image>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, 1)"
              type="text"
              size="small"
              >修改</el-button
            >
            <el-button
              @click="handleClick(scope.row, 2)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import draggable from "vuedraggable";

export default {
  components: { Breadcrumb, Pagination, draggable },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "线路管理",
        },
      ], // 面包屑数据
      line: "",

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.travelRegionGetFixedRegionList,
      method: "POST",
      params: JSON.stringify({}),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    handleAdd() {
      this.$router.push("/worldLine/AddLine");
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.travelRegionGetFixedRegionList,
        method: "POST",
        params: JSON.stringify({
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row, type) {
      if (type == 1) {
        this.$router.push("/worldLine/EditLine");
        localStorage.setItem("lineRow", JSON.stringify(row));
      } else if (type == 2) {
        this.$confirm("您确认要删除此路线吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            showLoading();
            const opt = {
              url: reqUrl.travelRegionDeleteFixedTravel,
              method: "POST",
              params: JSON.stringify({
                id: row.id,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.refresh();
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    reset() {
      this.city = "";
      this.country = "";
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  justify-content: space-between;
  .el-input {
    width: 300px;
    margin-right: 10px;
  }
}
</style>
